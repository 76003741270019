import {ErrorHandler, Injectable} from '@angular/core'
import {HttpErrorResponse} from '@angular/common/http'

@Injectable()
export class GlobalerrorhandlerService implements ErrorHandler {

  // The exception message
  message: string

  constructor(
    // private injector: Injector
  ) { }

  // Custom error handler implementation
  handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      // Server error
      if (!navigator.onLine) {
        // No internet connection
        this.message = 'No internet connection.'
      } else {
        this.message = 'A server error happenned.'
      }
    } else {
      // Client error
      this.message = 'Something went wrong, please retry again.'
    }
    // const snackbarService = this.injector.get(ErrorHandlerSnackbarService);
    // snackbarService.throwErrorSnackbar(this.message);
    console.log(error.message)
    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error
  }

}