import {RouterModule, Routes} from '@angular/router'
import {NgModule} from '@angular/core'
import {PageNotFoundComponent} from './components/page_not_found.component'
import {AuthGuard, AuthModule} from '@velcotech/vui-lib'
import {LogoutComponent} from '@app/components/logout/logout.component'
import {RolesEnum} from '@app/shared/enum/roles.enum'

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => AuthModule,
  },
  {
    path: 'products',
    loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
    canActivate: [AuthGuard],
    data: {role: RolesEnum.USER},
  },
  {
    path: 'managers',
    loadChildren: () => import('./modules/manager/manager.module').then(m => m.ManagerModule),
    canActivate: [AuthGuard],
    data: {role: RolesEnum.USER},
  },
  {
    path: 'factory',
    loadChildren: () => import('./modules/factory/factory.module').then(m => m.FactoryModule),
    canActivate: [AuthGuard],
    data: {role: RolesEnum.USER},
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '',
    redirectTo: 'products',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRouting {
}
