import {Component, OnInit} from '@angular/core'
import {AuthService, DestroyUtilsComponent} from '@velcotech/vui-lib'
import {Router} from '@angular/router'
import {takeUntil} from 'rxjs/operators'

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent extends DestroyUtilsComponent {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    super()
    this.authService.logOut()
      .pipe(takeUntil(this.componentDestroy))
      .subscribe(() => this.router.navigateByUrl('/auth/login'))
  }
}
