import {Observable, throwError as observableThrowError} from 'rxjs'
import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'

import {BuildInformation} from '@app/models/buildInforamtion.models'
import {catchError} from 'rxjs/operators'
import {UrlUtils} from '@app/services/url.utils'

@Injectable({providedIn: 'root'})
export class BuildService {
  private buildUrl = UrlUtils.BASE_URL_V1 + '/build'

  constructor(private http: HttpClient) {
  }

  getBuildVersion(): Observable<BuildInformation> {
    return this.http
      .get<BuildInformation>(this.buildUrl)
      .pipe(catchError(this.handleError))
  }

  handleError(error: Response) {
    console.log(error)
    return observableThrowError(error)
  }
}
