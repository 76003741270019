import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core'
import {BuildService} from './services/build.service'
import {BuildInformation} from './models/buildInforamtion.models'
import {LoaderService} from '@app/services/loader.service'
import {AuthService, DestroyUtilsComponent} from '@velcotech/vui-lib'
import {Router} from '@angular/router'
import {takeUntil} from 'rxjs/operators'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent extends DestroyUtilsComponent implements OnInit, AfterViewInit {
  isLoggedIn: boolean
  buildInformation: BuildInformation
  loading: boolean
  environment = 'PROD'

  constructor(private authService: AuthService,
              private buildService: BuildService,
              private loaderService: LoaderService,
              private cdr: ChangeDetectorRef,
              private router: Router) {
    super()
    this.isLoggedIn = this.authService.isUserLoggedIn()
  }

  ngOnInit(): void {
    this.getEnvironment(window.location.origin)
    this.buildService.getBuildVersion().subscribe(response => {
      this.buildInformation = response
    })

    this.authService.getLoggedInSubject()
      .asObservable()
      .pipe(takeUntil(this.componentDestroy))
      .subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn
      })
  }

  ngAfterViewInit(): void {
    this.loaderService.isLoading.subscribe((loading) => {
      this.loading = loading
      this.cdr.detectChanges()
    })
  }

  getEnvironment(locationOrigin: string): void {

    if (locationOrigin.includes('localhost')) {
      this.environment = 'DEV'
    } else if (locationOrigin.includes('integration')) {
      this.environment = 'INTEG'
    } else if (locationOrigin.includes('qa')) {
      this.environment = 'QA'
    } else if (locationOrigin.includes('stage')) {
      this.environment = 'STAGE'
    }

  }

  onLogout(): void {
    this.router.navigateByUrl('/logout').then()
  }
}
