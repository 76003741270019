import {ErrorHandler, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {AppComponent} from './app.component'
import {MatTabsModule} from '@angular/material/tabs'
import {GlobalerrorhandlerService} from './services/errorshandlers/globalerrorhandler.service'
import {PageNotFoundComponent} from './components/page_not_found.component'
import {AppRouting} from './app.routing'
import {LoaderService} from '@app/services/loader.service'
import {LoaderInterceptor} from '@app/interceptor/loader.interceptor'
import {MatProgressBarModule} from '@angular/material/progress-bar'
import {RouterModule} from '@angular/router'
import {NgPipesModule} from 'ngx-pipes'
import {NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule} from '@angular-material-components/datetime-picker'
import {AuthGuard,
  AuthService,
  AuthInterceptor,
  LangEnum,
  LoadingInterceptor, ModalsModule, ToastModule} from '@velcotech/vui-lib'
import {environment} from '../environments/environment'
import {LogoutComponent} from './components/logout/logout.component'
import PackageJson from '../../package.json'

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LogoutComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRouting,
    MatTabsModule,
    MatProgressBarModule,
    NgPipesModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    ModalsModule,
    ToastModule,
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalerrorhandlerService,
    },

    // Loader
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private authService: AuthService
  ) {
    authService.init({
      version: 'v' + PackageJson.version, // Application version
      title: 'Velco Internal Dashboard',        //Title in browser
      key: 'VID',                            //Application code in Velco SI
      xapikey: environment.xapikey,          //Needed for communication with backoffice
      routeOnLoginSuccessForUser: 'products',    //Route to redirect when an user is logged
      routeOnLoginSuccessForAdmin: 'products',  //Route to redirect when an admin is logged
      settingsUrl: '',      //Will be called on login to save locale preference
      publicPaths: [],                       //Routes always allowed by AuthGuard
      languages: [LangEnum.EN],    // Available languages
    })
  }
}
